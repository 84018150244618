import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import "./Enrollment.css";
import { useState, useRef, useEffect } from 'react';
import { validEmailFormat } from '../../../Utils/regex';
import axios from 'axios';
import { APIUrl } from '../../../Services/env';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


export const Enrollment = () => {

  const [studentDetails, setStudentDetails] = useState({
    title: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    fresherOrExp: "",
    clgOrOfc: "",
    emailId: "",
    mobileNumber: "",
    otp: "",
    pin: "",
    addressLine: "",
    city: "",
    dist: "",
    state: "",
    resume: ""
  })

  const [studentDetailsErr, setStudentDetailsErr] = useState({
    titleErr: "",
    firstNameErr: "",
    lastNameErr: "",
    genderErr: "",
    dobErr: "",
    fresherOrExpErr: "",
    clgOrOfcErr: "",
    emailIdErr: "",
    mobileNumberErr: "",
    otpErr: "",
    pinErr: "",
    addressLineErr: "",
    cityErr: "",
    distErr: "",
    stateErr: "",
    resumeErr: ""
  })

  const [dobType, setDobType] = useState("text");
  const [clgOfc, setClgOfc] = useState("");
  const [mobDisable, setmobDisable] = useState(false);
  const [sendOtpDisable, setSendOtpDisable] = useState(false);
  const [otpDisable, setOtpDisable] = useState(false);
  const [verifyDisable, setVerifyDisable] = useState(false);
  const [verifyBtnName, setVerifyBtnName] = useState("Verify OTP");
  const [resumeFileName, setResumeFileName] = useState("");
  const [checkGender, setCheckGender] = useState("");
  const [resumeFile, setResumeFile] = useState();

  const fileInput = useRef(null);
  const navigate = useNavigate()

  const selectTitle = (e) => {
    studentDetails.title = e
    setStudentDetails((prev) => ({ ...prev }));
    // setStudentDetailsErr()
    if (studentDetails.title.length === 0) {
      studentDetailsErr.titleErr = "Please select";
      setStudentDetailsErr((prev) => ({ ...prev }));
    } else {
      if (e === "Mr.") {
        setCheckGender("Mr.")
        studentDetails.gender = "Mr."
        // eslint-disable-next-line
        setStudentDetails((prev) => ({ ...prev, ["gender"]: "Male" }));
      } else if (e === "Mrs.") {
        setCheckGender("Mrs.")
        studentDetails.gender = "Mrs."
        // eslint-disable-next-line
        setStudentDetails((prev) => ({ ...prev, ["gender"]: "Female" }));
      } else if (e === "Miss") {
        setCheckGender("Miss")
        studentDetails.gender = "Miss"
        // eslint-disable-next-line
        setStudentDetails((prev) => ({ ...prev, ["gender"]: "Female" }));
      }
      studentDetailsErr.titleErr = "";
      setStudentDetailsErr((prev) => ({ ...prev }));
    }
  }

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setStudentDetails((prev) => ({ ...prev, [name]: value }));
    if (name === "gender") {
      studentDetailsErr.genderErr = "";
      setStudentDetailsErr((prev) => ({ ...prev }));
    }

    if (name === "fresherOrExp") {
      if (value === "Fresher") {
        setClgOfc("College Name")
      } else if ("Exp") {
        setClgOfc("Company Name")
      }
    }

    if (name === "pin") {
      if (value.length > 6) {
        value = value.slice(0, 6);
      } else {
        studentDetailsErr.pinErr = "";
        setStudentDetailsErr((prev) => ({ ...prev }));
      }
    }

    if (name === "mobileNumber") {
      if (value.length > 10) {
        value = value.slice(0, 10);
      } else {
        studentDetailsErr.pinErr = "";
        setStudentDetailsErr((prev) => ({ ...prev }));
      }
    }

    if (name === "otp") {
      if (value.length > 4) {
        value = value.slice(0, 4);
      } else {
        studentDetailsErr.pinErr = "";
        setStudentDetailsErr((prev) => ({ ...prev }));
      }
    }

    if (name === "resume") {
      setResumeFile(e.target.files[0])
    //   console.log(studentDetails.resume)
    //   console.log(studentDetails.resumeFileName)
    //   let resumePath = studentDetails.resume.split(".");
    //   let fileExtension = resumePath[resumePath.length - 1]
    //   let supportedFiles = ["pdf", "docx", "jpg"]

    //   console.log(resumePath)
    //   console.log(fileExtension)
    //   console.log(supportedFiles.includes(fileExtension.toLowerCase()))
    //   if (!supportedFiles.includes(fileExtension.toLowerCase())) {
    //     studentDetailsErr.resumeErr = "Please provide PDF or docx file";
    //     setStudentDetailsErr((prev) => ({ ...prev }));
    //   } else if (e.target.files && e.target.files[0].size > 2e6) {
    //     studentDetailsErr.resumeErr = "File size should lessthan 2MB";
    //     setStudentDetailsErr((prev) => ({ ...prev }));
    //   } else {
    //     studentDetailsErr.resumeErr = "";
    //     setStudentDetailsErr((prev) => ({ ...prev }));
    //   }
     }

  }

  const handleOnBlur = (e) => {
    // console.log(e.target.name);
    switch (e.target.name) {

      case "firstName": {
        if (studentDetails.title.length === 0) {
          studentDetailsErr.titleErr = "Please select";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else if (studentDetails.firstName.length === 0) {
          studentDetailsErr.firstNameErr = "Please enter your first name";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.firstNameErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "lastName": {
        if (studentDetails.lastName.length === 0) {
          studentDetailsErr.lastNameErr = "Please enter your last name";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.lastNameErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "gender": {
        if (studentDetails.gender.length === 0) {
          studentDetailsErr.lastNameErr = "Please choose your gender";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.lastNameErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "dob": {
        changeType()
        if (studentDetails.dob.length === 0) {
          studentDetailsErr.dobErr = "Please choose your DOB";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.dobErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "fresherOrExp": {
        if (studentDetails.fresherOrExp.length === 0) {
          studentDetailsErr.fresherOrExpErr = "Please choose your gender";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.fresherOrExpErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "clgOrOfc": {
        if (studentDetails.clgOrOfc.length === 0) {
          studentDetailsErr.clgOrOfcErr = `Please enter your ${clgOfc} `;
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.clgOrOfcErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "emailId": {
        if (studentDetails.emailId.length === 0) {
          studentDetailsErr.emailIdErr = "Please enter your email id";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          if (!validEmailFormat.test(studentDetails.emailId)) {
            studentDetailsErr.emailIdErr = "Please enter correct email id";
            setStudentDetailsErr((prev) => ({ ...prev }));
          } else {
            studentDetailsErr.emailIdErr = "";
            setStudentDetailsErr((prev) => ({ ...prev }));
          }
        }
        break;
      }

      case "mobileNumber": {
        if (studentDetails.mobileNumber.length === 0) {
          studentDetailsErr.mobileNumberErr = "Please enter your mobile number";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        else {
          studentDetailsErr.mobileNumberErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "otp": {
        if (studentDetails.otp.length === 0) {
          studentDetailsErr.otp = "Please enter your OTP";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        else {
          studentDetailsErr.otpErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "pin": {
        if (studentDetails.pin.length === 0) {
          studentDetailsErr.pinErr = "Please enter your PIN code";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          if (studentDetails.pin.length !== 6) {
            studentDetailsErr.pinErr = "Please enter 6 digit PIN"
          } else {
            axios.get("https://api.postalpincode.in/pincode/" + studentDetails.pin)
              .then((response) => response.data)
              .then((data) => {
                if (data[0].Status === "Success") {
                  dataAfterPINFetch(data[0].PostOffice)
                  studentDetailsErr.pinErr = "";
                  setStudentDetailsErr((prev) => ({ ...prev }));
                } else if (data[0].Status === "Error") {
                  studentDetailsErr.pinErr = "Please Enter Correct PIN";
                  setStudentDetailsErr((prev) => ({ ...prev }));
                }
                // console.log(data)
              })
          }
        }
        break;
      }

      case "addressLine": {
        if (studentDetails.addressLine.length === 0) {
          studentDetailsErr.addressLineErr = "Please enter your address";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.addressLineErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "city": {
        if (studentDetails.city.length === 0) {
          studentDetailsErr.cityErr = "Please enter your city";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.cityErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "dist": {
        if (studentDetails.dist.length === 0) {
          studentDetailsErr.distErr = "Please enter your district";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.distErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      case "state": {
        if (studentDetails.state.length === 0) {
          studentDetailsErr.stateErr = "Please enter your state";
          setStudentDetailsErr((prev) => ({ ...prev }));
        } else {
          studentDetailsErr.stateErr = "";
          setStudentDetailsErr((prev) => ({ ...prev }));
        }
        break;
      }

      default:
        break;
    }
  }

  const dataAfterPINFetch = (pinData) => {
    // console.log(pinData)
    // eslint-disable-next-line
    pinData.map((x) => {
      if (x.BranchType === "Sub Post Office") {
        studentDetails.city = x.Name;
        studentDetails.dist = x.District;
        studentDetails.state = x.State;
        setStudentDetails((prev) => ({ ...prev }));
      }
    })
  }

  const sendOTP = (e) => {
    e.preventDefault();
    if (studentDetails.mobileNumber === "") {
      toast.error("Please enter mobile number");
      studentDetailsErr.mobileNumberErr = "Enter mobile number";
      setStudentDetailsErr((prev) => ({ ...prev }));
    } else if (studentDetails.mobileNumber.length !== 10) {
      toast.error("Please enter correct mobile number");
      studentDetailsErr.mobileNumberErr = "Enter correct mobile number";
      setStudentDetailsErr((prev) => ({ ...prev }));
    } else {
      studentDetails.mobileNumberErr = "";
      setStudentDetailsErr((prev) => ({ ...prev }));
      axios.post(APIUrl + "sendotp", {
        params: studentDetails.mobileNumber
      })
        .then((response) => response.data)
        .then((data) => {
          if (data.message === "Success") {
            toast.success(data.data.message)
            setmobDisable(true)
            setVerifyDisable(false)
          } else if (data.message === "Failed") {
            toast.error(data.data.message)
            setVerifyDisable(true)
          }
        })
    }
  }

  const verifyotp = (e) => {
    e.preventDefault();
    if (studentDetails.mobileNumber === "") {
      toast.error("Please enter mobile number");
      studentDetailsErr.mobileNumberErr = "Enter mobile number";
      setStudentDetailsErr((prev) => ({ ...prev }));
    } else if (studentDetails.mobileNumber.length !== 10) {
      toast.error("Please enter correct mobile number");
      studentDetailsErr.mobileNumberErr = "Enter correct mobile number";
      setStudentDetailsErr((prev) => ({ ...prev }));
    } else if (studentDetails.otp === "") {
      toast.error("Please enter OTP");
      studentDetailsErr.otpErr = "Enter OTP";
      setStudentDetailsErr((prev) => ({ ...prev }));
    } else if (studentDetails.otp.length !== 4) {
      toast.error("Please enter correct OTP");
      studentDetailsErr.otpErr = "Enter correct OTP";
      setStudentDetailsErr((prev) => ({ ...prev }));
    } else {
      studentDetailsErr.mobileNumberErr = "";
      studentDetailsErr.otpErr = "";
      setStudentDetailsErr((prev) => ({ ...prev }));

      let otpVerifyObj = {
        mobNum: studentDetails.mobileNumber,
        otp: studentDetails.otp
      }
      axios.post(APIUrl + "verifyotp", {
        params: otpVerifyObj
      }).then((response) => response.data)
        .then((data) => {
          // console.log(data)
          if (data.message === "Success") {
            toast.success(data.data.message);
            setSendOtpDisable(true);
            setOtpDisable(true);
            setVerifyDisable(true)
            setVerifyBtnName("Verified")
          } else if (data.message === "Failed") {
            toast.error(data.data.message)
            setVerifyBtnName("Verify OTP")
          }
        })
    }
  }

  const changeType = () => {
    setDobType(dobType === "date" ? "text" : "date")
  }

  const enrollmentSubmit = (e) => {
    e.preventDefault();
    if (studentDetails.title === "") {
      studentDetailsErr.titleErr = "Please select";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Please select Title")
    } else if (studentDetails.firstName === "") {
      studentDetailsErr.firstNameErr = "Enter your first name";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter your first name")
    } else if (studentDetails.lastName === "") {
      studentDetailsErr.lastNameErr = "Enter your last name";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter your last name")
    } else if (studentDetails.gender === "") {
      studentDetailsErr.genderErr = "Please select gender";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Please select gender")
    } else if (studentDetails.dob === "") {
      studentDetailsErr.dobErr = "Enter your DOB";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter your DOB")
    } else if (studentDetails.fresherOrExp === "") {
      studentDetailsErr.fresherOrExpErr = "Select Fresher or Exp.";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Select Fresher or Exp.")
    } else if (studentDetails.clgOrOfc === "") {
      studentDetailsErr.clgOrOfcErr = `Enter ${clgOfc}`;
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error(`Enter ${clgOfc}`)
    } else if (studentDetails.emailId === "") {
      studentDetailsErr.emailIdErr = "Enter your Email ID";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter your Email ID.")
    } else if (studentDetails.mobileNumber === "") {
      studentDetailsErr.mobileNumberErr = "Enter mobile number";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter mobile number")
    } else if (sendOtpDisable === false) {
      toast.error("Click on Send OTP to verify")
    } else if (studentDetails.otp === "") {
      studentDetailsErr.otpErr = "";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter mobile number")
    } else if (verifyDisable === false) {
      toast.error("Click on Verify OTP to verify")
    } else if (studentDetails.pin === "") {
      studentDetailsErr.pinErr = "Enter PIN";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter PIN")
    } else if (studentDetails.addressLine === "") {
      studentDetailsErr.addressLineErr = "Enter Address Line";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter Address Line")
    } else if (studentDetails.city === "") {
      studentDetailsErr.cityErr = "Enter City";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter City")
    } else if (studentDetails.dist === "") {
      studentDetailsErr.distErr = "Enter District";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter District")
    } else if (studentDetails.state === "") {
      studentDetailsErr.distErr = "Enter State";
      setStudentDetailsErr((prev) => ({ ...prev }));
      toast.error("Enter State")
    } else if (studentDetailsErr.resumeErr !== "") {
      toast.error("File should in .docx/.pdf format and size lessthan 2MB")
    } else {
      axios.post(APIUrl + "enrollment", { 
        params: {
          studentDetails,
          //resumeFile
      }  })
        .then((response) => response.data)
        .then((data) => {
          if (data.message === "Success") {
            toast.success("Form Submitted Successfully")
            navigate("/thankyou")
          }else if (data.message === "Failed") {
            toast.error("Something Went Wrong. Please Try Again After Sometime !!!")
          }
        })
    }
  }
  //console.log(studentDetails)

  useEffect(() => {
    if (studentDetails.resume !== "") {
      let resumePath = studentDetails.resume.split(".");
      let fileExtension = resumePath[resumePath.length - 1]
      let supportedFiles = ["pdf", "docx"];
      if (!supportedFiles.includes(fileExtension.toLowerCase())) {
        studentDetailsErr.resumeErr = "Please provide PDF or docx file";
        setStudentDetailsErr((prev) => ({ ...prev }));
        setResumeFileName("")
      } else {
        // let fileName = studentDetails.resume.split("\\");
        // setResumeFileName(fileName[fileName.length - 1])
        console.log(resumeFile.name)
        setResumeFileName(resumeFile.name)
        studentDetailsErr.resumeErr = "";
        setStudentDetailsErr((prev) => ({ ...prev }));
      }
    }
    // eslint-disable-next-line
  }, [studentDetails.resume])

  return (
    <>
      <div className="enrollmentSection">
        <div className="enrollmentContent container">
          <h2 className="text-center">Enrollment Form</h2>
          <div className="enrollmentFormSection">
            <form action="post" onSubmit={enrollmentSubmit}
              className="enrollmentForm">
              <div className="row">
                <div className="col-md-2">
                  <DropdownButton title="Title" onSelect={selectTitle} onBlur={handleOnBlur}
                    variant='secondary'>
                    <Dropdown.Item eventKey={"Mr."}>Mr.</Dropdown.Item>
                    <Dropdown.Item eventKey={"Mrs."}>Mrs.</Dropdown.Item>
                    <Dropdown.Item eventKey={"Miss"}>Miss</Dropdown.Item>
                  </DropdownButton>
                  <div className="err titleErr">
                    {studentDetailsErr.titleErr}
                  </div>
                </div>
                <div className="col-md-1">
                  {studentDetails.title}
                  <div className="err titleErr">
                    {/* {studentDetailsErr.titleErr} */}
                  </div>
                </div>
                <div className="col-md-5">
                  <input type="text" name="firstName" id="firstname"
                    value={studentDetails.firstName} onChange={handleOnChange}
                    onBlur={handleOnBlur} placeholder='First Name' />
                  <div className="err firstNameErr">
                    {studentDetailsErr.firstNameErr}
                  </div>
                </div>
                <div className="col-md-4">
                  <input type="text" name="lastName" id="lastName"
                    value={studentDetails.lastName} onChange={handleOnChange} onBlur={handleOnBlur}
                    placeholder='Last Name' />
                  <div className="err lastNameErr">
                    {studentDetailsErr.lastNameErr}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 radioBtnRow">
                  <div className="row">
                    <div className="col-md-4 radioBtns">
                      Male
                      <input type="radio" name="gender" id="genderMale" value="Male"
                        onChange={handleOnChange} onBlur={handleOnBlur} checked={checkGender === "Mr."} />
                    </div>
                    <div className="col-md-4 radioBtns">
                      Female
                      <input type="radio" name="gender" id="genderFemale"
                        value="Female" onChange={handleOnChange} onBlur={handleOnBlur} checked={checkGender === "Mrs." || checkGender === "Miss"} />
                    </div>
                    <div className="col-md-4 radioBtns">
                      Other
                      <input type="radio" name="gender" id="genderMale"
                        value="Other" onChange={handleOnChange} onBlur={handleOnBlur} />
                    </div>
                  </div>
                  <div className="err genderErr">
                    {studentDetailsErr.genderErr}
                  </div>
                </div>
                <div className="col-md-7">
                  <input type={dobType} name="dob" id="dob"
                    value={studentDetails.dob} placeholder='Date Of Birth'
                    onFocus={changeType} onBlur={handleOnBlur} onChange={handleOnChange} />
                  <div className="err dobErr">
                    {studentDetailsErr.dobErr}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 radioBtnRow">
                  <div className="row">
                    <div className="col-md-6 radioBtns">
                      Fresher
                      <input type="radio" name="fresherOrExp" id="fresherRadioBtn"
                        value="Fresher" onChange={handleOnChange} onBlur={handleOnBlur} />
                    </div>
                    <div className="col-md-6 radioBtns">
                      Experience
                      <input type="radio" name="fresherOrExp" id="expRadioBtn"
                        value="Exp" onChange={handleOnChange} onBlur={handleOnBlur} />
                    </div>
                  </div>
                  <div className="err fresherOrExpErr">
                    {studentDetailsErr.fresherOrExpErr}
                  </div>
                </div>
                <div className="col-md-7">
                  <input type="text" name="clgOrOfc" id="fresherOrExpInput"
                    value={studentDetails.clgOrOfc} onChange={handleOnChange} onBlur={handleOnBlur}
                    placeholder={clgOfc !== "" ? clgOfc : "College / Company Name"
                    } />
                  {/* {studentDetails.fresherOrExp} */}
                  <div className="err clgOrOfcErr">
                    {studentDetailsErr.clgOrOfcErr}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-12">
                  <input type="text" name="emailId" id="emailId"
                    placeholder='Email Id' value={studentDetails.emailId}
                    onChange={handleOnChange} onBlur={handleOnBlur} />
                  <div className="err emailIdErr">
                    {studentDetailsErr.emailIdErr}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <input type="number" name="mobileNumber" id="mobileNumber"
                    value={studentDetails.mobileNumber} onChange={handleOnChange} onBlur={handleOnBlur} disabled={mobDisable}
                    placeholder='Mobile Number' />
                  <div className="err mobileNumberErr">
                    {studentDetailsErr.mobileNumberErr}
                  </div>
                </div>
                <div className="col-md-2">
                  <button type='button' className='verifyOTP' onClick={sendOTP} disabled={sendOtpDisable}>Send OTP</button>
                  <div className="err otpErr"></div>
                </div>
                <div className="col-md-4">
                  <input type="number" name="otp" id="otp"
                    value={studentDetails.otp} onChange={handleOnChange} onBlur={handleOnBlur} disabled={otpDisable}
                    placeholder='Enter OTP' />
                  <div className="err otpErr">
                    {studentDetailsErr.otpErr}
                  </div>
                </div>
                <div className="col-md-2">
                  <button type='button' onClick={verifyotp} className='verifyOTP' disabled={verifyDisable}>{verifyBtnName}</button>
                  <div className="err otpErr"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <input type="number" name="pin" id="pin"
                    value={studentDetails.pin} onChange={handleOnChange} onBlur={handleOnBlur} maxLength={6}
                    placeholder='PIN Code' />
                  <div className="err pinErr">
                    {studentDetailsErr.pinErr}
                  </div>
                </div>
                <div className="col-md-8">
                  <textarea type="text" name='addressLine'
                    value={studentDetails.addressLine} onChange={handleOnChange} onBlur={handleOnBlur}
                    placeholder='Address Line' rows={2}></textarea>
                  <div className="err addressLineErr">
                    {studentDetailsErr.addressLineErr}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <input type="text" name="city" id="city" onChange={handleOnChange} onBlur={handleOnBlur}
                    placeholder='City' value={studentDetails.city} />
                  <div className="err cityErr">
                    {studentDetailsErr.cityErr}
                  </div>
                </div>
                <div className="col-md-4">
                  <input type="text" name="Dist" id="Dist" onChange={handleOnChange} onBlur={handleOnBlur}
                    placeholder='District' value={studentDetails.dist} />
                  <div className="err distErr">
                    {studentDetailsErr.distErr}
                  </div>
                </div>
                <div className="col-md-4">
                  <input type="text" name="state" id="state"
                    onChange={handleOnChange} onBlur={handleOnBlur} placeholder='State'
                    value={studentDetails.state} />
                  <div className="err addressLineErr">
                    {studentDetailsErr.StateErr}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  {/* <label for="resume" class="sr-only">Upload Resume</label> */}
                  <input type="file" name="resume" id="resume" ref={fileInput} placeholder='Upload Resume' value={studentDetails.resume}
                    onChange={handleOnChange} style={{ display: 'none' }} />
                  <button type='button' className='upload-btn' onClick={() => fileInput.current.click()}>
                    Upload Resume&nbsp;<span className='optional'>{`(Optional)`}</span>
                  </button>
                  <div className="err resumeErr">
                    {studentDetailsErr.resumeErr}
                  </div>
                </div>
                <div className="col-md-6">
                  <input type='text' name='resumeFileName' placeholder='Uploaded file Name' value={resumeFileName} disabled />
                  <div className="err resumeErr"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button type="submit">Send Request</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}