import "./BootCarousel.css";
import banner1 from "../../../Assets/images/banner2.jpg"
// import banner1 from "../../Assets/images/banner2.jpg"
import banner2 from "../../../Assets/images/banner3.jpg"

export const BannerCarousel = () => {
   return (
      <>
         <div className="bannerSection">
            <div className="bannerContent container">
               <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                  {/* <div className="carousel-indicators">
                     <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                     <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  </div> */}
                  <div className="carousel-inner">
                     <div className="carousel-item banner active" data-bs-interval="5000">
                        <img src={banner1} className="d-block" alt="Banner" />
                     </div>
                     <div className="carousel-item banner" data-bs-interval="5000">
                        <img src={banner2} className="d-block" alt="Banner" />
                     </div>
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                     <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                     <span className="carousel-control-next-icon" aria-hidden="true"></span>
                     <span className="visually-hidden">Next</span>
                  </button>
               </div>
            </div>
         </div>
      </>
   )
}


// export const FeedbackCarousel = () => {
//    return (
//       <>
//          <div className="bootCarouselSection">
//             <div className="bootCarouselContent container">
//                <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
//                   <div className="carousel-indicators">
//                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
//                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
//                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
//                   </div>
//                   <div className="carousel-inner">
//                      <div className="carousel-item active" data-bs-interval="10000">

//                      </div>
//                      <div className="carousel-item" data-bs-interval="2000">

//                      </div>
//                      <div className="carousel-item">

//                      </div>
//                   </div>
//                   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
//                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//                      <span className="visually-hidden">Previous</span>
//                   </button>
//                   <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
//                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
//                      <span className="visually-hidden">Next</span>
//                   </button>
//                </div>
//             </div>
//          </div>


//       </>
//    )
// }

// export const FeedbackCarousel = () => {
//    return (
//       <>
//          <div className="bootCarouselSection">
//             <div className="bootCarouselContent container">
//                <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
//                   {/* <div className="carousel-indicators">
//                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
//                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
//                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
//                   </div> */}
//                   <div className="carousel-inner">
//                      <div className="carousel-item active" data-bs-interval="5000">
//                         <div className="totalContent">
//                            <div className="topContent">
//                               “Thanks for a great course !!! This has more than met my expectations. You presented the topics in enthusiastic way. The way you interacted, accepted each recommendation from us as a step of improvement and explaining everything was outstanding. I really feel that we got a lot from the training - truly appreciate it !! ”
//                            </div>
//                            <div className='middleContent'>
//                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill='#373a6d' d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg>
//                            </div>
//                            <div className="bottomContent">
//                               <div className="userInitial">
//                                  A
//                               </div>
//                            </div>
//                            <div className="userName">
//                               Abhishek
//                            </div>
//                         </div>
//                         {/* <div className="carousel-caption d-none d-md-block">
//                            <h5>First slide label</h5>
//                            <p>Some representative placeholder content for the first slide.</p>
//                         </div> */}
//                      </div>
//                      <div className="carousel-item" data-bs-interval="5000">
//                         <div className="totalContent">
//                            <div className="topContent">
//                               “Thank you so much, Karthik for your teaching passion. He is always very kind, patient, helpful, and truly cares.I don't have any experience in software development and basics things. He always teaching any concept very very simple methods.
//                               I learned so many things. Now I move zero to the next level.
//                               Special thanks to Vignesh for providing a user-friendly trainer.”
//                            </div>
//                            <div className='middleContent'>
//                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill='#373a6d' d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg>
//                            </div>
//                            <div className="bottomContent">
//                               <div className="userInitial">
//                                  P
//                                  {/* <img src="" alt='User'></img> */}
//                               </div>
//                            </div>
//                            <div className="userName">
//                               Prabhakaran
//                            </div>
//                         </div>
//                         {/* <img src="..." className="d-block w-100" alt="..." />
//                         <div className="carousel-caption d-none d-md-block">
//                            <h5>Second slide label</h5>
//                            <p>Some representative placeholder content for the second slide.</p>
//                         </div> */}
//                      </div>
//                      <div className="carousel-item">
//                         <img src="..." className="d-block w-100" alt="..." />
//                         <div className="carousel-caption d-none d-md-block">
//                            <h5>Third slide label</h5>
//                            <p>Some representative placeholder content for the third slide.</p>
//                         </div>
//                      </div>
//                   </div>
//                   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
//                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//                      <span className="visually-hidden">Previous</span>
//                   </button>
//                   <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
//                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
//                      <span className="visually-hidden">Next</span>
//                   </button>
//                </div>
//             </div>
//          </div >


//          {/* <script>
//             $(".carousel").swipe({
//                swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
//                     if (direction == 'left') $(this).carousel('next');
//             if (direction == 'right') $(this).carousel('prev');
//                 },
//             allowPageScroll: "vertical"
//             });
//          </script> */}
//          {/* <script>
//             $(document).ready(function() {
//                // Make carousel items draggable
//                $(".carousel-inner").draggable()
//             });
//          </script> */}
//       </>


//    )
// } 