import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppLayout } from './Component/AppLayout/AppLayout';
import { Dashboard } from './Component/Dashboard/Dashboard';
import { Enrollment } from './Views/Forms/Enrollment/Enrollment';
import { ThankYou } from './Views/Forms/ThankYou/ThankYou';
import { DemoFeedback } from './Views/Forms/DemoFeedback/DemoFeedback';
import { AboutTutor } from './Views/AboutTutor/AboutTutor';
import './App.css';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout></AppLayout>,
      children: [
        {
          path: "/",
          element: <Dashboard></Dashboard>
        },
        {
          path: "/enrollment",
          element: <Enrollment />
        },
        {
          path: "/thankyou",
          element: <ThankYou />
        },
        {
          path: "/demofeedback",
          element: <DemoFeedback />
        },
        {
          path: "/abouttutor",
          element: <AboutTutor />
        }
      ]
    }
  ])
  return <RouterProvider router={router} />
}

export default App;
