import { useState } from "react"
import "./DemoFeedback.css"
import axios from "axios"
import { APIUrl } from "../../../Services/env"
import toast from "react-hot-toast"

export const DemoFeedback = () => {

   const [feedback, setFeedback] = useState({
      fullName: "",
      message: ""
   })

   const [feedbackErr, setFeedbackErr] = useState({
      fullNameErr: "",
      messageErr: ""
   })

   const handleOnChange = (e) => {
      let { name, value } = e.target;
      setFeedback((prev) => ({ ...prev, [name]: value }));
   }

   const saveDemoFeedback = (e) => {
      e.preventDefault();

      if (feedback.fullName === "") {
         feedbackErr.fullNameErr = "Enter your full name";
         setFeedbackErr((prev) => ({ ...prev }));
      } else if (feedback.message === "") {
         feedbackErr.fullNameErr = "Enter your feedback";
         setFeedbackErr((prev) => ({ ...prev }));
      } else {
         feedbackErr.fullNameErr = "";
         feedbackErr.fullNameErr = "";
         setFeedbackErr((prev) => ({ ...prev }));

         axios.post(APIUrl + "savedemofeedback", { params: feedback })
            .then((response) => response.data)
            .then((data) => {
               if (data.message === "Success") {
                  toast.success(data.data.message)
                  feedback.fullName = ""
                  feedback.message = ""
                  setFeedback((prev) => ({ ...prev }));
               } else {
                  toast.error(data.data.message)
               }
            })
      }
   }

   return (
      <>
         <div className="demoFeedbackSection">
            <div className="demoFeedbackContent container">
               <h2 className="text-center">Demo Feedback Form</h2>
               <div className="feedbackForm">
                  <form action="post" className="demoFeedbackForm" onSubmit={saveDemoFeedback}>
                     <div className="row">
                        <div className="col-md-12 mb-2">
                           <input type="text" name="fullName" id="fullName" value={feedback.fullName}
                              onChange={handleOnChange} placeholder="Full Name" />
                           <div className="err">
                              {feedbackErr.fullNameErr}
                           </div>
                        </div>
                        <div className="col-md-12">
                           <textarea name="message" id="message" value={feedback.message} placeholder="Type your Feedback"
                              onChange={handleOnChange} rows="5"></textarea>
                           <div className="err">
                              {feedbackErr.messageErr}
                           </div>
                        </div>
                        <div className="col-md-12">
                           <button type="submit">Submit Feedback</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </>
   )
}