import { FeedbackNewCarousel } from "../../Component/Carousel/FeedbackCarousel/FeedbackCarousel"
// import { CustomerFeedback } from "../../Component/OwlCarousel/OwlCarousel"
import "./HappyCustomer.css"

export const HappyCustomer = () => {
  return (
    <>
      <div className="happyCustomerSection" id="testimonials">
        <div className="happyCustomerContent container">
          <div className="happyCustomerHeading">
            <h2 className="text-center">Testimonials</h2>
            {/* <CustomerFeedback></CustomerFeedback> */}
            <FeedbackNewCarousel />
          </div>
        </div>
      </div>
    </>

  )
}