import { BannerCarousel } from "../../Component/Carousel/BootCarousel/BootCarousel"
// import { BannerImage } from "../../Component/OwlCarousel/OwlCarousel"
import "./Home.css"


export const Home = () => {
  return (
    <>
      <div className="homeSection">
        <div className="homeContent container">
          <div className="bannerSection">
            <div className="bannerText align-self-center">
              <h1>TechnoTutor Institute of Technology</h1>
              <p className="subHeading">Best Online Training Institute</p>
              <p className="mt-4"><a href="#contact">Contact Me</a></p>
            </div>
            <div className="bannerImg">
              {/* <BannerImage></BannerImage> */}
              <BannerCarousel />
            </div>
          </div>
        </div>
      </div >
    </>
  )
}