// import { Home } from "../../Views/Home/Home"
import { MainNavbar } from "../Navbar/Navbar"
// import { About } from "../../Views/About/About"
// import { Training } from "../../Views/Training/Training"
// import { HappyCustomer } from "../../Views/HappyCustomer/HappyCustomer"
// import { Services } from "../../Views/Services/Services"
// import { Blog } from "../../Views/Blog/Blog"
// import { ContactMe } from "../../Views/ContactMe/ContactMe"
import { Footer } from "../../Views/Footer/Footer"
import { Outlet } from "react-router-dom"
import { Toaster } from "react-hot-toast"

export const AppLayout = () => {
  return (
    <>
      <MainNavbar></MainNavbar>
      <Toaster></Toaster>
      <Outlet></Outlet>
      <Footer></Footer>
    </>
  )
}