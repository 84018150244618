import "./Curriculum.css";
import angularLogo from "../../Assets/Icon/angularicon.png";
import database from "../../Assets/Icon/databse2.png";
// import devops from "../../Assets/Icon/devops.png";
import dot from "../../Assets/Icon/7744865.png";
import dotnet from "../../Assets/Icon/dotnet1.png"
import { angularTopicList, databaseTopicList, devopsTopicList, dotnetTopicList } from "../../Data/curriculumList";

export const Curriculum = () => {
  return (
    <>
      <div className="curriculumSection" id="curriculum">
        <div className="curriculumContent container">
          <h2>Curriculum</h2>
          <div className="row desc mt-4">
            <div className="col-md-3 col-lg-3 col-xl-3 curriDesc">
              <div className="heading mb-4">
                {/* <img src={devops} alt="Database" className="devops"></img> */}
                <h4>Web Design</h4>
              </div>
              <div className="topicList">
                {devopsTopicList.map((x, i) => {
                  return (
                    <div className="topic">
                      <div className="dot" key={i}>
                        <img src={dot} alt="dot" />
                      </div>
                      {x}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 curriDesc">
              <div className="heading">
                <img src={angularLogo} alt="Angular" className="angular" />
                <h4>Angular</h4>
              </div>
              <div className="topicList">
                {angularTopicList.map((x, i) => {
                  return (
                    <div className="topic" key={i} >
                      <div className="dot" >
                        <img src={dot} alt="dot" />
                      </div>
                      {x}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 curriDesc">
              <div className="heading">
                <img src={dotnet} alt="" />
                <div className="headingName">
                  <div className="topHeading">
                    &nbsp;Microsoft
                  </div>
                  <div className="bottomHeading">
                    .NET
                  </div>
                </div>

              </div>
              <div className="topicList">
                {dotnetTopicList.map((x, i) => {
                  return (
                    <div className="topic" key={i}>
                      <div className="dot">
                        <img src={dot} alt="dot" />
                      </div>
                      {x}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 curriDesc">
              <div className="heading">
                <img src={database} alt="Database" className="database"></img>
                <h4>Database</h4>
              </div>
              <div className="topicList">
                {databaseTopicList.map((x, i) => {
                  return (
                    <div className="topic" key={i}>
                      <div className="dot" >
                        <img src={dot} alt="dot" />
                      </div>
                      {x}
                    </div>
                  )
                })}
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}