import { NavLink } from "react-router-dom";
import thankyou from "../../../Assets/images/thankyou2.jpg"
import "./ThankYou.css"


export const ThankYou = () => {

   return (
      <>
         <div className="thankYouSection">
            <div className="thankYouContent container">
               <div className="thankYouImg">
                  <img src={thankyou} alt="ThankYou" />
               </div>
               <h4 className="text-center">We will reach you very soon !!!</h4>
               <h6 className="text-center mt-4">Click <NavLink to="/">Here</NavLink> Navigate Homepage</h6>
            </div>
         </div>
      </>
   )
}