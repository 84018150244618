import { About } from "../../Views/About/About"
import { AdmissionDtls } from "../../Views/AdmissionDtls/AdmissionDtls"
import { ContactMe } from "../../Views/ContactMe/ContactMe"
import { CourseOverview } from "../../Views/CourseOverview/CourseOverview"
import { Curriculum } from "../../Views/Curriculum/Curriculum"
import { HappyCustomer } from "../../Views/HappyCustomer/HappyCustomer"
import { Home } from "../../Views/Home/Home"
// import { FeedbackNewCarousel } from "../Carousel/FeedbackCarousel/FeedbackCarousel"
import "./Dashboard.css"

export const Dashboard = () => {
  return (
    <>
      <Home></Home>
      <About></About>
      <CourseOverview></CourseOverview>
      <Curriculum ></Curriculum>
      <HappyCustomer></HappyCustomer>
      <AdmissionDtls></AdmissionDtls>
      <ContactMe></ContactMe>
      {/* <FeedbackNewCarousel></FeedbackNewCarousel> */}
    </>
  )
}