import { useState } from "react";
import "./ContactMe.css";
import { validEmailFormat } from "../../Utils/regex";
import toast from "react-hot-toast";
import axios from "axios";

export const ContactMe = () => {
  const [contactMeData, setContactMeData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    message: ""
  })

  const [contactMeDataErr, setContactMeDataErr] = useState({
    firstNameErr: "",
    lastNameErr: "",
    emailIdErr: "",
    messageErr: ""
  })

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setContactMeData((prev) => ({ ...prev, [name]: value }));
  }

  const validateOnBlur = (e) => {
    // alert("her")
    switch (e.target.id) {
      case "firstName":
        if (contactMeData.firstName.length < 1) {
          contactMeDataErr.firstNameErr = "Enter The Name";
          setContactMeDataErr((prev) => ({ ...prev }));
        } else if (contactMeData.firstName.length > 30) {
          contactMeDataErr.firstNameErr = "Enter 30 Characters";
          setContactMeDataErr((prev) => ({ ...prev }));
        } else {
          contactMeDataErr.firstNameErr = "";
          setContactMeDataErr((prev) => ({ ...prev }));
        }
        break;

      case "lastName":
        alert(contactMeData.lastName.length)
        if (contactMeData.lastName.length < 1) {
          contactMeDataErr.lastNameErr = "Enter The Last Name";
          setContactMeDataErr((prev) => ({ ...prev }));
        } else if (contactMeData.lastName.length > 30) {
          contactMeDataErr.lastNameErr = "Enter 30 Characters";
          setContactMeDataErr((prev) => ({ ...prev }));
        } else {
          contactMeDataErr.lastNameErr = "";
          setContactMeDataErr((prev) => ({ ...prev }));
        }
        break;

      case "emailId":
        if (!validEmailFormat.test(contactMeData.emailId)) {
          contactMeDataErr.emailIdErr = "Enter The Correct Email ID";
          setContactMeDataErr((prev) => ({ ...prev }));
        } else {
          contactMeDataErr.emailIdErr = "";
          setContactMeDataErr((prev) => ({ ...prev }));
        }
        break;

      case "message":
        if (contactMeData.message.length < 1) {
          contactMeDataErr.messageErr = "Enter The message";
          setContactMeDataErr((prev) => ({ ...prev }));
        } else if (contactMeData.message.length > 500) {
          contactMeDataErr.messageErr = "Enter Lessthan 500 Characters";
          setContactMeDataErr((prev) => ({ ...prev }));
        } else if (contactMeData.message.length < 30) {
          contactMeDataErr.messageErr = "Enter Minimum 30 Characters";
          setContactMeDataErr((prev) => ({ ...prev }));
        } else {
          contactMeDataErr.messageErr = "";
          setContactMeDataErr((prev) => ({ ...prev }));
        }
        break;
      default:
        break;
    }

  }

  const contactMe = (e) => {
    e.preventDefault();
    if (contactMeData.firstName !== "" && contactMeData.lastName !== "" && contactMeData.emailId !== "" && contactMeData.message !== "") {
      if (contactMeDataErr.firstNameErr === "" && contactMeDataErr.lastNameErr === "" && contactMeDataErr.emailIdErr === "" && contactMeDataErr.messageErr === "") {
        try {
          axios.post("http://localhost:5000/technotutor/savecontactrequest", { params: contactMeData })
            .then((response) => response.data)
            .then((data) => {
              // console.log(data)
              if (data.message === "Success") {
                // console.log(data)
                setContactMeData({
                  firstName: "",
                  lastName: "",
                  emailId: "",
                  message: ""
                })
                toast.success(`We got your Request. We will reach you very soon...`)
              } else {
                toast.error("Something went wrong !!!")
              }
            })
        } catch (error) {
          console.log(error)
        }
      } else {
        toast.error('Please Enter Correct Details !');
      }
    } else {
      toast.error('Please Fill Form !');
    }
    console.log(contactMeData)
  }

  return (
    <>
      <div className="contactSection" id="contact">
        <div className="conactContent container">
          <h3 className="text-center">Contact Me</h3>
          <div className="row my-5 contactDtls">
            {/* <div className="col-md-4">
              <p className="contactMeHeading">Address</p>
              <p className="contactMeDesc">E-1502, Western Avenue, Wakad, Pune, Maharastra, IND</p>
            </div> */}
            <div className="col-md-6 ">
              <p className="contactMeHeading">Phone</p>
              <p className="contactMeDesc"><a href="tel:+918982039282">+91 898 203 9282</a>
              </p>
            </div>
            <div className="col-md-6 text-r">
              <p className="contactMeHeading">Email Address</p>
              <p className="contactMeDesc"><a href="mailto:saxena.kartik14@outlook.com" target="_blank" rel="noreferrer">saxena.kartik14@outlook.com</a></p>
            </div>
          </div>
          <form className="contactForm" onSubmit={contactMe}>
            <div className="row">
              <div className="col-md-6">
                <input type="text" maxLength={30} id="firstName" placeholder="First Name" onChange={handleOnChange} onBlur={validateOnBlur} name="firstName" value={contactMeData.firstName}></input>
              </div>
              <div className="col-md-6">
                <input type="text" maxLength={30} id="lastName" placeholder="Last Name" onChange={handleOnChange} onBlur={validateOnBlur} name="lastName" value={contactMeData.lastName}></input>
              </div>
              <div className="col-md-6 mb-2 error">
                {contactMeDataErr.firstNameErr}
              </div>
              <div className="col-md-6 mb-2 error">
                {contactMeDataErr.lastNameErr}
              </div>
              <div className="col-md-12">
                <input type="text" placeholder="Email Address" id="emailId" onChange={handleOnChange} onBlur={validateOnBlur} name="emailId" value={contactMeData.emailId}></input>
              </div>
              <div className="col-md-12 mb-2 error">
                {contactMeDataErr.emailIdErr}
              </div>
              <div className="col-md-12 ">
                <textarea type="text" maxLength={500} id="message" placeholder="Write Your Message" onChange={handleOnChange} onBlur={validateOnBlur} name="message" value={contactMeData.message}></textarea>
              </div>
              <div className="col-md-12 mb-2 error">
                {contactMeDataErr.messageErr}
              </div>
              <div className="col-md-12">
                <button type="submit" >Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}