export const angularTopicList = [
  "Components",
  "Component Interaction",
  "Data Binding & Event Binding",
  "Directives (Inbuilt & Custom)",
  "Routing",
  "Dependency Injection",
  "Modules",
  "Filter",
  "Services",
  "Angular Forms",
  "REST API Implementation",
  "Angular Scope",
  "Angular Material & Templates",

]

export const dotnetTopicList = [
  "Introduction of .NET",
  "Introduction of Visual Studio",
  "C# Fundamentals",
  "Identifiers and Keywords",
  "Variables and Constants",
  "Looping Statement",
  "Decision Making Statement",
  "Type Conversions",
  "Boxing and Unboxing",
  "Expressions and Operators",
]

export const databaseTopicList = [
  "Database Design",
  "Data modeling",
  "SQL",
  "Trasactions",
  "Currency Control",
  "Database Management Systems",
  "Databse Structure",
  "Databse Driven Application",
  "Data Modeling Concepts"
]

export const devopsTopicList = [
  "HTML",
  "CSS",
  "SCSS",
  "BootStrap",
  "JavaScript",
  "TypeScript",
]