import "./CourseOverview.css"
import bullet from "../../Assets/Icon/7744865.png"

export const CourseOverview = () => {
  return (
    <>
      <div className="courseOverviewSection">
        <div className="courseOverviewContent container">
          <h2 className="text-center mb-2">Course Overview</h2>
          <div className="overviewPoints">
            <div className="row">
              <div className="col-md-6">
                <div className="points">
                  <div className="dot">
                    <img src={bullet} alt="bullet"></img>
                  </div>
                  1 - on - 1 Mock interview with industry experts.
                </div>
                <div className="points">
                  <span className="dot">
                    <img src={bullet} alt="bullet"></img>
                  </span>
                  Learn AI tools to code faster, better and more precisely.
                </div>
                <div className="points">
                  <span className="dot">
                    <img src={bullet} alt="bullet"></img>
                  </span>
                  Live online classes and Lifetime Access to Recorded sessions.
                </div>
                {/* <div className="points">
                  <span className="dot">
                    <img src={bullet} alt="bullet"></img>
                  </span>
                  Advance in coading with 100+ Hours of Industry - Relevant Practical Projects
                </div> */}
              </div>
              <div className="col-md-6">
                <div className="points">
                  <span className="dot">
                    <img src={bullet} alt="bullet"></img>
                  </span>
                  Unlock Your carrier potential with our online training.
                </div>
                {/* <div className="points">
                  <span className="dot">
                    <img src={bullet} alt="bullet"></img>
                  </span>
                  EMI options available(Upto 12mnths) with 7 days return policy
                </div> */}
                {/* <div className="points">
                  <span className="dot">
                    <img src={bullet} alt="bullet"></img>
                  </span>
                  Unlimited access to programing Practice Platform with 1500+ Problem
                </div> */}
                <div className="points">
                  <span className="dot">
                    <img src={bullet} alt="bullet"></img>
                  </span>
                  Advance in coading with 100+ Hours of Industry - Relevant Practical Projects
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}