import logo from "../../Assets/images/logo-1.png"
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { useState } from "react";


export const MainNavbar = () => {
  const [showHide, setShowHide] = useState(false)
  // const [width, setWidth] = useState("")

  const showHideNavDraw = () => {
    setShowHide(true)
    // setWidth("300px")
  }

  const closeNavDraw = () => {
    setShowHide(false)
    // setWidth("0px")
  }

  return (
    <>
      <div className="navbarSection">
        <div className="navbarContent container">
          <div className="navbar">
            <NavLink to="/" className="navLinkLogo">
              <img src={logo} alt="Logo"></img>
              TechnoTutor
            </NavLink>
            <div className="linkSection">
              <NavLink to="/" className="navlink" >Home</NavLink>
              <NavLink to="/Abouttutor" className="navlink" >About</NavLink>
              <NavLink to="/demofeedback" className="navlink">Demo Feedback</NavLink>
              <NavLink to="/enrollment" className="navlink">Enrollment</NavLink>
            </div>
            <div className="menuBtnSection">
              {/* {[false].map((expand) => (
                <Navbar key={expand} expand={expand} className="navbar-dark">
                  <Container fluid>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                      id={`offcanvasNavbar-expand-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                      placement="end"
                    >
                      <div >
                        <Offcanvas.Header closeButton ref={closeBtn} >
                        </Offcanvas.Header>
                      </div>

                      <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 px-4">
                          <NavLink to="/" className="menuNavLink" onClick={() => closeBtn.current.click()}>Home</NavLink>
                          <NavLink to="/Abouttutor" className="menuNavLink" onClick={() => closeBtn.current.click()}>About</NavLink>
                          <NavLink to="/demofeedback" className="menuNavLink">Demo Feedback</NavLink>
                          <NavLink to="/enrollment" className="menuNavLink">Enrollment</NavLink>
                        </Nav>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
              ))} */}
              <div className="menuIcon" onClick={showHideNavDraw}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ffffff" d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" /></svg>
              </div>

              {showHide &&
                <div className="menuDrawer">
                  <div className="closeBtn" onClick={closeNavDraw}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#373a6d" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                  </div>
                  <ul>
                    <li><NavLink to="/" className="menuNavLink" onClick={closeNavDraw}>Home</NavLink></li>
                    <li><NavLink to="/Abouttutor" className="menuNavLink" onClick={closeNavDraw}>About</NavLink></li>
                    <li><NavLink to="/demofeedback" className="menuNavLink" onClick={closeNavDraw}>Demo Feedback</NavLink></li>
                    <li><NavLink to="/enrollment" className="menuNavLink" onClick={closeNavDraw}>Enrollment</NavLink></li>
                  </ul>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}