import "./AdmissionDtls.css";

export const AdmissionDtls = () => {
  return (
    <>
      <div className="admissionDtlsSection" id="admission">
        <div className="admissionContet container">
          <h2 className="text-center text-white">Admission Details</h2>
          <div className="admissionDtls">
            <div className="row topLine">
              <div className="col-md-4 ">
                <div className="dtlBox">
                  <h4 className="text-center">Eligibility</h4>
                  <hr></hr>
                  <div className="text-center"> Graduate </div>
                  <div className="text-center"> {`(BCA, MCA, BE/B.Tech)`} </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="dtlBox">
                  <h4 className="text-center">Batch Timing</h4>
                  <hr></hr>
                  <div className="row">
                    <div className=" sec">Demo Class Date -</div>
                    <div className=" sec">21.09.24 {`(Saturday)`}</div>
                  </div>
                  <div className="row">
                    <div className=" sec">Demo Class Timing -</div>
                    <div className=" sec">12:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="dtlBox bottomLine">
                  <h4 className="text-center">Fee Structure</h4>
                  <hr></hr>
                  <div className="row">
                    <div className="sec"> Web Design - </div> <div className="sec text-end"><span ><del>&#8377; 5,999.00</del><span className="offer">{`(20% OFF)`}</span> </span> &#8377; 4,799.00 </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="sec">Angular - </div> <div className="sec text-end"><span ><del>&#8377; 9,999.00</del><span className="offer">{`(25% OFF)`}</span> </span> &#8377; 7,499.00 </div>
                  </div>
                  <div className="row">
                    <div className="sec">.NET Core - </div> <div className="sec text-end"> <span ><del>&#8377; 14,999.00</del><span className="offer">{`(25% OFF)`}</span> </span> &#8377; 11,249.00 </div>
                  </div>
                  <div className="row">
                    <div className="sec">Database - </div> <div className="sec text-end"><span ><del>&#8377; 9,999.00</del><span className="offer">{`(25% OFF)`}</span> </span> &#8377; 7,499.00 </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="sec">Fullstack Development-</div> <div className="sec text-end">  <span ><del>&#8377; 34,997.00</del> <span className="offer">{`(25% OFF)`}</span></span>&#8377;  26,250.00 </div>
                  </div>
                </div>
                <div className="totalFee"><h4>Total Fee</h4><h4>&#8377; 26,250.00 <span className="offer">{`(25% OFF)`}</span></h4></div>
              </div>
            </div>
          </div>






          {/* <div className="admissionDtls row">
            <div className="col-md-6 admissionBox">
              <h4 className="text-center">Batch Timing</h4>
              <hr></hr>
              <div className="admissionBoxContent" >
                <br></br>
                <br></br>
                <div className="row admissionContentLine">
                  <div className=" sec">Demo Class Date -</div>
                  <div className=" sec">21.09.24 {`(Saturday)`}</div>
                </div>
                <div className="row admissionContentLine">
                  <div className=" sec">Demo Class Timing -</div>
                  <div className=" sec">12:00 PM</div>
                </div>
              </div>
            </div>

            <div className="col-md-6 admissionBox">
              <h4 className="text-center">Eligibility</h4>
              <hr></hr>
              <div className="admissionBoxContent text-center">
                <br></br>
                <br></br>
                <br></br>
                <div>12<sup>th</sup> Pass</div>
              </div>
            </div>

            <div className="col-md-12 admissionBox">
              <div className="">
                <h4 className="text-center">Fee Structure</h4>
                <hr></hr>
                <div className="row">
                  <div className="sec"> Web Design - </div> <div className="sec text-end"><span ><del>&#8377; 5,999.00</del><span className="offer">{`(20% OFF)`}</span> </span> &#8377; 4,799.00 </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="sec">Angular - </div> <div className="sec text-end"><span ><del>&#8377; 5,999.00</del><span className="offer">{`(20% OFF)`}</span> </span> &#8377; 9,999.00 </div>
                </div>
                <div className="row">
                  <div className="sec">.NET Core - </div> <div className="sec text-end"> <span ><del>&#8377; 5,999.00</del><span className="offer">{`(20% OFF)`}</span> </span> &#8377; 14,999.00 </div>
                </div>
                <div className="row">
                  <div className="sec">Database - </div> <div className="sec text-end"><span ><del>&#8377; 5,999.00</del><span className="offer">{`(20% OFF)`}</span> </span> &#8377; 9,999.00 </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="sec">Fullstack Development-</div> <div className="sec text-end">  <span ><del>&#8377; 34,997.00</del> </span>&#8377;  26,250.00 </div>
                </div>
              </div>
              <div className=" totalFee"><h4>Total Fee</h4><h4>&#8377; 26,250.00 <span className="offer">{`(25% OFF)`}</span></h4></div>
            </div> 
          </div>*/}
        </div>
      </div>
    </>
  )
}