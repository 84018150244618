import venki from "../../../Assets/images/venki.jpg"
import riti from "../../../Assets/images/riti1.jpg"
import "./FeedbackCarousel.css"
export const FeedbackNewCarousel = () => {
   return (
      <>
         <div className="bootCarouselSection">
            <div className="bootCarouselContent container">
               <div id="carouselExampleDark1" className="carousel carousel-dark slide" data-bs-ride="carousel" >
                  <div className="carousel-inner" oncopy="return false">
                     <div className="carousel-item active" data-bs-interval="5000">
                        <div className="totalContent">
                           <div className="topContent">
                              “Kartik Sir's teaching skill is super. My education is from non IT backgroud. Before join, my thought was IT filed will be very tough. But the way kartik sir taught me, I feel very easy to learn. Now i am working as a Fullstack Developer.
                              Thank's to god, I got a teacher like Kartik Sir. For me kartik sir is not only a teacher, but also a big brother.  He is the encyclopedia of software field.”
                           </div>
                           <div className='middleContent'>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill='#373a6d' d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg>
                           </div>
                           <div className="bottomContent">
                              <div className="userInitial">
                                 <img src={riti} alt='User'></img>
                              </div>
                           </div>
                           <div className="userName">
                              Ritikrishna
                           </div>
                        </div>
                     </div>
                     <div className="carousel-item " data-bs-interval="5000">
                        <div className="totalContent">
                           <div className="topContent">
                              “Thanks for a great course !!! This has more than met my expectations. You presented the topics in enthusiastic way. The way you interacted, accepted each recommendation from us as a step of improvement and explaining everything was outstanding. I really feel that we got a lot from the training - truly appreciate it !! ”
                           </div>
                           <div className='middleContent'>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill='#373a6d' d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg>
                           </div>
                           <div className="bottomContent">
                              <div className="userInitial">
                                 A
                              </div>
                           </div>
                           <div className="userName">
                              Abhishek
                           </div>
                        </div>
                     </div>
                     <div className="carousel-item" data-bs-interval="5000">
                        <div className="totalContent">
                           <div className="topContent">
                              “Thank you so much, Karthik for your teaching passion. He is always very kind, patient, helpful, and truly cares.I don't have any experience in software development and basics things. He always teaching any concept very very simple methods.
                              I learned so many things. Now I move zero to the next level.
                              Special thanks to Vignesh for providing a user-friendly trainer.”
                           </div>
                           <div className='middleContent'>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill='#373a6d' d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg>
                           </div>
                           <div className="bottomContent">
                              <div className="userInitial">
                                 P
                                 {/* <img src="" alt='User'></img> */}
                              </div>
                           </div>
                           <div className="userName">
                              Prabhakaran
                           </div>
                        </div>
                     </div>
                     <div className="carousel-item" data-bs-interval="5000">
                        <div className="totalContent">
                           <div className="topContent">
                              “Karthik was an exceptional teacher who helped me tremendously when I joined the institute with little
                              knowledge. He explained concepts clearly and was always patient in addressing my doubts. His calm
                              demeanor and approachable nature made learning easy, and he ensured that every student understood the
                              topics thoroughly. His dedication to teaching truly set him apart.”
                           </div>
                           <div className='middleContent'>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill='#373a6d' d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg>
                           </div>
                           <div className="bottomContent">
                              <div className="userInitial">
                                 <img src={venki} alt='User'></img>
                              </div>
                           </div>
                           <div className="userName">
                              Venkatesh
                           </div>
                        </div>
                     </div>



                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark1" data-bs-slide="prev">
                     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                     <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark1" data-bs-slide="next">
                     <span className="carousel-control-next-icon" aria-hidden="true"></span>
                     <span className="visually-hidden">Next</span>
                  </button>
               </div>
            </div>
         </div>


      </>
   )
}

// export const FeedbackCarousel = () => {